import styled, { css } from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import { Link } from 'gatsby-plugin-intl'
import MUIBurgerIcon from '@material-ui/icons/Menu'

export const LogoWrapper = styled(Link)`
  height: 100%;
  justify-content: center;
`

export const Logo = styled.img`
  display: inline-block;
  height: 100%;
  width: auto;
  box-sizing: border-box;
  margin-left: 4%;
  padding: 22px 0 22px 0;
  ${breakpoint.xs`
    margin-left: 12%;
    padding: 19px 0 18px 0;
  `}
`

export const Burger = styled(MUIBurgerIcon)`
  font-size: 42px !important;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()} !important;
  margin-right: 1.4rem;
  opacity: 0.8;
  cursor: pointer;
`

export const NavigationList = styled.ul`
  height: 100%;
  align-items: center;
  padding-right: 18px;
  padding-right: 42px;
  display: none; //desktop navigation not visible on mobile
  justify-content: center;
  flex-direction: row;
  ${breakpoint.m`
    display: flex;
  `}
`

export const NavigationElement = styled.li`
  display: inline-flex;
  height: 100%;
  align-items: center;
  position: relative;
`

export const BurgerWrapper = styled(NavigationElement)`
  ${breakpoint.m`
    display: none;
  `}
`

export const MobileNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${NavigationElement} {
    margin-bottom: 1.2rem;
  }
`

export const NavigationLink = styled(Link)`
  display: inline-flex;
  padding: 0.8rem 0.8rem;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.brightGray.hex()};
  position: relative;
  &:hover {
    color: ${({ theme }) => theme.colors.ebonyClay.hex()};
    opacity: 1;
    position: relative;
    &:after {
      content: '';
      width: calc(100% - 1.2rem);
      height: 2px;
      position: absolute;
      bottom: -1px;
      left: 0.6rem;
      background-color: ${({ theme }) => theme.colors.apple.hex()};
    }
  }
  ${({ active }) =>
    active &&
    css`
      font-weight: 600;
      opacity: 1;
      &:after {
        content: '';
        width: calc(100% - 1.2rem);
        height: 2px;
        position: absolute;
        bottom: -1px;
        left: 0.6rem;
        background-color: ${({ theme }) => theme.colors.apple.hex()};
      }
    `}

  ${({ accent }) =>
    accent &&
    css`
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.colors.ebonyClay.hex()};
      background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
      color: white;
      padding: 0.6rem 1.8rem;
      opacity: 1;
      transition: all 0.2s ease-in-out;
      margin: 0 0.8rem;
      font-weight: 600;
      &:hover {
        background: none;
      }
      &:after {
        display: none;
      }
    `}
`

export const PopupNavigationElement = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.875rem;
  line-height: 1.71;
  padding: 0.6rem 1rem;
  color: ${({ theme }) => theme.colors.black.hex()};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  opacity: 1;
  transition: font-weight 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.athensGrayLight.hex()};
  }
  > span {
    margin-left: 0.8rem;
  }
  &:nth-last-child(2) {
    margin-top: 0.4rem;
  }
  ${({ active, theme }) =>
    active &&
    `
    background-color: ${theme.colors.athensGrayLight.hex()};
    font-weight: 600;
  `}
`
