import styled, { css } from 'styled-components'
import ExampleUserAvatarUrl from 'src/assets/avatar-placeholder.svg'

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50%;
  flex-shrink: 0;
  background-image: url(${({ url }) => url || ExampleUserAvatarUrl});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  ${({ small }) =>
    small &&
    css`
      width: 24px;
      height: 24px;
    `}
${({ medium }) =>
  medium &&
  css`
    width: 32px;
    height: 32px;
  `}
  ${({ big }) =>
    big &&
    css`
      width: 160px;
      height: 160px;
      box-shadow: 0 0 20px 0 rgba(36, 45, 63, 0.24);
    `}
`
