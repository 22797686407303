import React from 'react'
import EuFlagUrl from 'src/assets/images/flaga_ue.jpg'
import { useDispatch } from 'react-redux'
import { openEUFinancingDialog } from 'src/ducks/actions'

const EuFinancingLogoSmall = () => {
  const dispatch = useDispatch()
  return (
    <a onClick={() => dispatch(openEUFinancingDialog())}>
      <img src={EuFlagUrl} style={{ width: '60px', marginRight: '30px' }} />
    </a>
  )
}

export default EuFinancingLogoSmall
