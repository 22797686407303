import React from 'react'
import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Alert from 'src/components/Alert'
import { connectSnackbar } from 'src/ducks/connectors'
import { isEmpty } from 'ramda'
import themeData from 'src/theme/theme'

const useStyles = makeStyles({
  root: {
    top: `calc(${themeData.dimensions.topBarHeight} + 8px)`
  }
})

const GlobalSnackbar = ({
  snackbar: { open, type, message, title },
  setSnackbar,
  className
}) => {
  const classes = useStyles()

  return (
    <Snackbar
      classes={{ root: classes.root }}
      open={open}
      onClose={() => setSnackbar({ open: false })}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      {!isEmpty(message) && (
        <Alert
          type={type}
          message={message}
          className={className}
          size={28}
          title={title}
        />
      )}
    </Snackbar>
  )
}
const ConnectedComponent = connectSnackbar(GlobalSnackbar)

export default styled(ConnectedComponent)`
  box-shadow: 0 0 20px 0 rgba(36, 45, 63, 0.24);
  border: 1px solid ${({ theme }) => theme.colors.outerSpace.alpha(0.4).hex()};
  min-width: 290px;
  min-height: 65px;
  font-size: 0.9rem;
  font-weight: 500;
`
