import { useStaticQuery, graphql } from 'gatsby'

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            navigation {
              main {
                links {
                  name
                  path
                }
              }
              footer {
                links {
                  name
                  path
                }
              }
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}
