import chroma from 'chroma-js'

export default {
  white: chroma('#fff'),
  whiteTwo: chroma('#fbfbfb'),
  whiteThree: chroma('#fefefe'),
  black: chroma('#333'),
  outerSpace: chroma('#2D3237'),
  ebonyClay: chroma('#242d3f'),
  mirage: chroma('#1e2534'),
  brightGray: chroma('#373e4c'),
  wildSand: chroma('#f5f5f5'),
  alabaster: chroma('#fbfbfb'),
  apple: chroma('#47aa42'),
  lightGreen: chroma('#edf7ec'),
  lightGrey: chroma('#f1f1f3'),
  corduroy: chroma('#585a59'),
  easternBlue: chroma('#13a2bf'),
  mercury: chroma('#e3e3e3'),
  gallery: chroma('#ededed'),
  error: chroma('#ff1744'),
  fadedRed: chroma('#d04d52'),
  ghost: chroma('#c6cbd4'),
  info: chroma('#ddf2f6'),
  success: chroma('#d6f3e2'),
  warning: chroma('#ffeacc'),
  alert: chroma('#f9d3d4'),
  codGray: chroma('#1a1a1a'),
  iron: chroma('#dddfe2'),
  athensGray: chroma('#eaeaec'),
  athensGrayLight: chroma('#f8f8f9'),
  california: chroma('#f89406'),
  bluewood: chroma('#384661'),
  catskillWhite: chroma('#F2F4F8'),
  frenchGray: chroma('#BABAC4'),
  iceberg: chroma('#D7EFF4'),
  geyser: chroma('#cfd5e3'),
  transparent: chroma('#fff').alpha(0),
  hawkesBlue: chroma('#dbe1ea'),
  deYork: chroma('#7dc37a')
}

/*
    if you are looking for an inspiration how to name a color look here:
    http://chir.ag/projects/name-that-color
*/
