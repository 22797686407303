import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import styled from 'styled-components'

const Progress = ({ className }) => (
  <div className={className}>
    <LinearProgress color='primary' />
  </div>
)

export default styled(Progress)`
  width: 100%;
  ${({ loading }) =>
    !loading &&
    `
    display: none;
  `}
`
