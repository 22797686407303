import React, { useState } from 'react'
import styled from 'styled-components'
import { Location } from '@reach/router'
import { useIntl } from 'gatsby-plugin-intl'
import routes from 'src/utils/routes'
import LogoUrl from 'src/assets/logo-gray.png'

import { useSiteMetadata } from 'src/hooks/useSiteMetadata'
import { LoaderStyled } from 'src/components/header/HeaderLoggedIn'
import { connectGlobalLoader } from 'src/ducks/connectors'
import {
  LogoWrapper,
  Logo,
  NavigationList,
  NavigationElement,
  NavigationLink,
  Burger,
  BurgerWrapper,
  MobileNavWrapper
} from './components/atoms'
import Drawer from './components/Drawer'
import EuFinancingLogoSmall from './components/EuFinancingLogoSmall'

const Header = ({ className, isGlobalLoaderLoading }) => {
  const intl = useIntl()
  const [drawerOpen, setOpen] = useState(false)
  const {
    navigation: {
      main: { links }
    }
  } = useSiteMetadata()

  return (
    <Location>
      {({ location: { pathname } }) => (
        <>
          <nav className={className}>
            <LoaderStyled loading={isGlobalLoaderLoading} />
            <LogoWrapper to={routes.HOME}>
              <Logo src={LogoUrl} alt='logo' />
            </LogoWrapper>
            <NavigationList>
              <EuFinancingLogoSmall />

              {links.map((link, index) => {
                {
                  /* exclude jobs from non plish pages */
                }
                if (link.name == 'jobs' && intl.locale !== 'pl') {
                  return null
                }
                return (
                  <NavigationElement key={index}>
                    <NavigationLink
                      to={link.path}
                      active={pathname.includes(link.path)}
                      accent={link.path === routes.SIGN_IN}
                    >
                      {intl.formatMessage({ id: `navigation.${link.name}` })}
                    </NavigationLink>
                  </NavigationElement>
                )
              })}
            </NavigationList>
            <BurgerWrapper>
              <Burger onClick={() => setOpen(!drawerOpen)} />
            </BurgerWrapper>
          </nav>
          <Drawer open={drawerOpen} onClose={() => setOpen(false)}>
            <MobileNavWrapper>
              {links.map((link, index) => (
                <NavigationElement key={index}>
                  <NavigationLink
                    to={link.path}
                    onClick={() => setOpen(false)}
                    active={pathname.includes(link.path)}
                    accent={link.path === routes.SIGN_IN}
                  >
                    {intl.formatMessage({ id: `navigation.${link.name}` })}
                  </NavigationLink>
                </NavigationElement>
              ))}
            </MobileNavWrapper>
          </Drawer>
        </>
      )}
    </Location>
  )
}

const connectedComponent = connectGlobalLoader(Header)

export default styled(connectedComponent)`
  display: flex;
  height: ${({ theme }) => theme.dimensions.topBarHeight};
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  z-index: 100;
  width: 100%;
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`
