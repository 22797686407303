import React from 'react'
import styled from 'styled-components'
import { Link, FormattedMessage } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'
import MUICloseIcon from '@material-ui/icons/Close'
import CookieImageUrl from 'src/assets/icon-awesome-cookie-bite.svg'
import routes from 'src/utils/routes'
import useLocalStorage from 'src/hooks/useLocalStorage'

const CookiesBar = ({ className }) => {
  const [informationRead, setRead] = useLocalStorage('cookie')

  return (
    !informationRead && (
      <div className={className}>
        <CloseIcon onClick={() => setRead(true)} />
        <Row>
          <CookieImage src={CookieImageUrl} alt='cookies' />
          <CookieDescription>
            <FormattedMessage id='cookies.messagePart1' />
            <Link to={routes.PRIVACY_POLICY}>
              <FormattedMessage id='cookies.link' />
            </Link>
            <FormattedMessage id='cookies.messagePart2' />
          </CookieDescription>
        </Row>
      </div>
    )
  )
}

export default styled(CookiesBar)`
  position: relative;
  display: flex;
  width: 100%;
  box-shadow: 0 -3px 10px 0 rgba(36, 45, 63, 0.15);
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  min-height: 88px;
  box-sizing: border-box;
  background-color: white;
  z-index: 1000;
  padding: 1.1rem 0.8rem;
  ${breakpoint.xs`
    padding: 1.1rem 3.2rem;
  `}
`

const CookieDescription = styled.span`
  display: block;
  font-size: 0.85rem;
  line-height: 1.64;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.brightGray.hex()};
  padding-right: 1.2rem;
  ${breakpoint.xs`
    padding-right: 3.4rem;
  `}
  a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
`

const CookieImage = styled.img`
  display: none;
  margin-right: 2.6rem;
  height: 36px;
  width: auto;
  margin-bottom: 0.8rem;
  ${breakpoint.xs`
    display: flex;
  `}
`

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  ${breakpoint.xs`
    flex-direction: row;
  `}
`

const CloseIcon = styled(MUICloseIcon)`
  color: ${({ theme }) => theme.colors.corduroy.hex()};
  margin-left: 0.6rem;
  margin-right: 0;
  font-size: 1.8rem !important;
  opacity: 0.8;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  ${breakpoint.m`
    font-size: 1.8rem !important;
  `}
`
