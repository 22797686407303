import React from 'react'
import styled from 'styled-components'
import { Link, useIntl } from 'gatsby-plugin-intl'

import routes from 'src/utils/routes'
import { breakpoint } from 'src/theme/grid'
import { useSiteMetadata } from 'src/hooks/useSiteMetadata'
import Language from 'src/intl/Language'

import LogoUrl from 'src/assets/logo-bright.png'
import Certificate1Url from 'src/assets/top-container-certificate.png'
import Certificate2Url from 'src/assets/rzetelna-firma.png'

const Footer = ({ className }) => {
  const intl = useIntl()
  const {
    navigation: {
      footer: { links }
    }
  } = useSiteMetadata()

  return (
    <footer className={className}>
      <Content>
        <LogoWrapper to={routes.HOME}>
          <Logo src={LogoUrl} alt='logo' />
        </LogoWrapper>
        <CertificateWrapper>
          <a
            href='https://wizytowka.rzetelnafirma.pl/pl?banner=GXQVWFRI'
            target='_blank'
          >
            <Certificate src={Certificate2Url} alt='certificate' />
          </a>
          <Link to={routes.CERTIFICATE}>
            <Certificate src={Certificate1Url} alt='certificate' />
          </Link>
        </CertificateWrapper>
      </Content>
      <BottomNavigation>
        <NavigationList>
          {links.map((link, index) => (
            <NavigationElement key={index}>
              <NavigationLink to={link.path}>
                {intl.formatMessage({ id: `navigation.${link.name}` })}
              </NavigationLink>
            </NavigationElement>
          ))}
        </NavigationList>
        <NavigationList>
          {/* <NavigationElement>
            <ContactLink href='tel:+48 662 401 789'>
              TEL: +48 662 401 789
            </ContactLink>
          </NavigationElement> */}
          <NavigationElement>
            <ContactLink href='mailto:bok@e-containers.eu'>
              bok@e-containers.eu
            </ContactLink>
          </NavigationElement>
          <NavigationElement>
            <Language />
          </NavigationElement>
        </NavigationList>
      </BottomNavigation>
    </footer>
  )
}

export default styled(Footer)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.apple.hex()};
  padding: 2rem 7% 1.2rem 7%;
  box-sizing: border-box;
  border-top: 8px solid ${({ theme }) => theme.colors.ebonyClay.hex()};
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0.8rem 0 2rem 0;
  ${breakpoint.s`
    flex-direction: row;
  `}
`

const LogoWrapper = styled(Link)`
  display: inline-flex;
  padding: 0 0.6rem 0.8rem 0;
`

const Logo = styled.img`
  display: inline-block;
  height: 30px;
  width: 180px;
  margin-bottom: 1.8rem;
  ${breakpoint.m`
    margin-bottom: 0;
    height: 43px;
    width: auto;
  `}
`

const BottomNavigation = styled.div`
  display: flex;
  color: rgba(255, 255, 255, 0.3);
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1.2rem;
  border-top: 1px solid white;
  margin-top: 1.2rem;
  width: 100%;
  box-sizing: border-box;
`

const CertificateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${breakpoint.sm`
    flex-direction: row;
  `}
`

const Certificate = styled.img`
  display: flex;
  height: 82px;
  width: auto;
  margin-left: 0;
  margin-bottom: 1.6rem;
  ${breakpoint.sm`
    margin-left: 3.2rem;
    margin-bottom: 0;
  `}
`

const NavigationList = styled.ul`
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  ${breakpoint.sm`
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `}
`

const NavigationElement = styled.li`
  display: inline-flex;
  height: 100%;
  align-items: center;
  position: relative;
`

const NavigationLink = styled(Link)`
  display: inline-flex;
  padding: 0.8rem 1.6rem 0.8rem 0;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  color: white;
  position: relative;
`

const ContactLink = styled.a`
  display: inline-flex;
  padding: 0.8rem;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  color: white;
  position: relative;
`
