import { css } from 'styled-components'
import { map, slice, values } from 'ramda'

export const breakpoints = {
  df: '0px',
  xxs: '400px',
  xs: '480px',
  s: '667px',
  sm: '769px',
  m: '1025px',
  xm: '1160px',
  l: '1280px',
  lm: '1440px',
  xl: '1600px',
  xxl: '2560px'
}

export const breakpointsArray = map(
  val => Number(slice(0, -2, val)),
  values(breakpoints)
)

/**
 * getMediaQuery
 * @param breakpoint {string} default 'm' or one of [df, xxs, xs, s, sm, m, xm, l, lm, xl, xxl]
 * @returns {string} eg. (min-width: 480px)
 */
export const mediaQuery = (breakpoint = 'm') =>
  `(min-width: ${breakpoints[breakpoint]})`

export const breakpoint = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}) {
      ${css(...args)};
    }
  `
  return acc
}, {})

const DEFAULT_GRID_GUTTER = '20px'
const DEFAULT_GRID_COLUMNS = 12
const GRID_BREAKPOINTS = ['df', 'xs', 's', 'm', 'xm', 'l', 'xl']

export const grid = {
  collapse: (gutter = DEFAULT_GRID_GUTTER) =>
    css`
      margin-left: -${gutter};
      margin-right: -${gutter};
      width: calc(100% + gutter + gutter);
    `,
  container: () =>
    css`
      &::after {
        clear: both;
        content: '';
        display: block;
      }
    `,
  breakpoints: (
    gridObj,
    columns = DEFAULT_GRID_GUTTER,
    gutter = DEFAULT_GRID_COLUMNS
  ) =>
    GRID_BREAKPOINTS.map(point => {
      if (gridObj[point]) {
        let column = gridObj[point] / columns
        let ratio = 1 + column
        let unit = gutter.replace(/[0-9]/g, '').replace(/[.,]/g, '')
        let unitlessGutter = gutter.replace(/[a-z]/g, '')
        return breakpoint[point]`
          width: calc( ${column * 100}% - ${parseFloat(unitlessGutter) *
          ratio}${unit} );
          float: left;
          margin-left: ${gutter};
        `
      }
    })
      .filter(styles => styles)
      .join('')
      .replace(/[,]/g, '')
}
