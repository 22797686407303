import { connect } from 'react-redux'

import {
  initDictionariesRoutine,
  fetchLocationsRoutine,
  fetchCompaniesRoutine,
  bootstrapApplicationRoutine,
  setSnackbarValuesRoutine
} from './actions'
import {
  selectDictionaries,
  selectLoadingDictionary,
  selectUnloadingDictionary,
  selectPickupDictionary,
  selectReturnDictionary,
  selectAvailabilityDictionary,
  selectDestinationDictionary,
  selectCompaniesDictionary,
  selectExchangeCounters,
  selectSnackbar,
  isGlobalLoaderLoading
} from './selectors'

export const connectDictionaries = connect(
  state => ({
    dictionaries: selectDictionaries(state),
    cities: [],
    terminals: [],
    loadingDictionary: selectLoadingDictionary(state),
    unloadingDictionary: selectUnloadingDictionary(state),
    pickupDictionary: selectPickupDictionary(state),
    returnDictionary: selectReturnDictionary(state),
    availabilityDictionary: selectAvailabilityDictionary(state),
    destinationDictionary: selectDestinationDictionary(state),
    companiesDictionary: selectCompaniesDictionary(state)
  }),
  dispatch => ({
    initDictionaries: () => dispatch(initDictionariesRoutine()),
    fetchLocations: payload => dispatch(fetchLocationsRoutine(payload)),
    fetchCompanies: payload => dispatch(fetchCompaniesRoutine(payload))
  })
)

export const connectExchangeCounters = connect(state => ({
  exchangeCounters: selectExchangeCounters(state)
}))

export const connectBootstrap = connect(
  () => ({}),
  dispatch => ({
    bootstrapApplication: () => dispatch(bootstrapApplicationRoutine())
  })
)

export const connectSnackbar = connect(
  state => ({
    snackbar: selectSnackbar(state)
  }),
  dispatch => ({
    setSnackbar: payload => dispatch(setSnackbarValuesRoutine(payload))
  })
)

export const connectGlobalLoader = connect(state => ({
  isGlobalLoaderLoading: isGlobalLoaderLoading(state)
}))
