import React from 'react'
import styled, { css } from 'styled-components'
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl'

const languageName = {
  en: 'English',
  pl: 'Polski',
  de: 'Deutsch'
}

const Language = () => (
  <div>
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) => (
        <Dropdown>
          <span>{currentLocale}</span>
          <ul>
            {languages.map(language => (
              <DropdownItem
                key={language}
                selected={currentLocale === language}
                onClick={() => changeLocale(language)}
              >
                {languageName[language]}
              </DropdownItem>
            ))}
          </ul>
        </Dropdown>
      )}
    </IntlContextConsumer>
  </div>
)

const Dropdown = styled.div`
  position: relative;

  span {
    margin: 0 30px;
  }

  ul {
    display: none;
    bottom: 20px;
    left: -80px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.alabaster.hex()};
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    &:hover {
      display: block;
    }
  }
  &:hover {
    color: ${({ theme }) => theme.colors.white.hex()};

    ul {
      display: block;
    }
  }
`

const DropdownItem = styled.a`
  color: ${({ selected, theme }) =>
    selected ? theme.colors.apple.hex() : 'black'};
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  margin: 10px;
  cursor: pointer;

  &:hover {
    color: black;
    font-weight: 800;
  }
  ${({ selected, theme }) =>
    selected &&
    css`
      color: ${theme.colors.apple.hex()};
      font-weight: 800;
    `}
`

export default Language
