import { connect } from 'react-redux'

import {
  setMessagesListOpenedRoutine,
  setConversationOpenedRoutine,
  sendMessageRoutine,
  bootstrapConversationRoutine,
  clearConversationRoutine,
  getLastMessagesListRoutine,
  searchForUserConversationsRoutine,
  clearLastMessagesListsRoutine,
  getLastContextualMessagesListRoutine,
  getLastPersonalMessagesListRoutine,
  muteChatRoutine,
  unmuteChatRoutine,
  muteConversationRoutine,
  unmuteConversationRoutine,
  markConversationAsSeenRoutine,
  markMessageAsSeenRoutine,
  reserveOfferRoutine,
  acceptReservationRoutine,
  rejectReservationRoutine,
  cancelReservationRoutine,
  getContextAvailableActionsRoutine,
  getConversationMessagesRoutine
} from './actions'

import {
  isMessagesListOpened,
  isConversationOpened,
  selectInterlocutor,
  selectConversationMessages,
  selectContextualMessages,
  selectPersonalMessages,
  selectMessagesCounters,
  selectPersonalSearchResults,
  selectContextualSearchResults,
  isMessagesListLoading,
  isChatMuted,
  isConversationMuted,
  selectConversation,
  selectOnlineUsers,
  selectConversationContextActions,
  isConversationContextLoading,
  isConversationLoading,
  selectLastMessageRequest,
  selectIsAvailable
} from './selectors'

export const connectMessages = connect(
  state => ({
    isAvailable: selectIsAvailable(state),
    lastRequest: selectLastMessageRequest(state),
    listOpened: isMessagesListOpened(state),
    conversationOpened: isConversationOpened(state),
    interlocutor: selectInterlocutor(state),
    messages: selectConversationMessages(state),
    personalMessages: selectPersonalMessages(state),
    contextualMessages: selectContextualMessages(state),
    messagesCounters: selectMessagesCounters(state),
    personalSearchResults: selectPersonalSearchResults(state),
    contextualSearchResults: selectContextualSearchResults(state),
    isMessagesListLoading: isMessagesListLoading(state),
    isChatMuted: isChatMuted(state),
    isConversationMuted: isConversationMuted(state),
    conversation: selectConversation(state),
    online: selectOnlineUsers(state),
    personalSearchResultsAllFetched:
      state.messages.personalSearchResultsAllFetched,
    contextualSearchResultsAllFetched:
      state.messages.contextualSearchResultsAllFetched,
    conversationContextActions: selectConversationContextActions(state),
    isConversationContextLoading: isConversationContextLoading(state),
    isConversationLoading: isConversationLoading(state)
  }),
  dispatch => ({
    setMessagesListOpened: opened =>
      dispatch(setMessagesListOpenedRoutine({ opened })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    sendMessage: ({ message, callback }) =>
      dispatch(sendMessageRoutine({ message, callback })),
    bootstrapConversation: () => dispatch(bootstrapConversationRoutine()),
    clearConversation: () => dispatch(clearConversationRoutine()),
    clearLastMessagesLists: () => dispatch(clearLastMessagesListsRoutine()),
    getLastMessagesList: () => dispatch(getLastMessagesListRoutine()),
    searchForUserConversations: payload =>
      dispatch(searchForUserConversationsRoutine(payload)),
    getLastContextualMessagesList: append =>
      dispatch(getLastContextualMessagesListRoutine({ append })),
    getLastPersonalMessagesList: append =>
      dispatch(getLastPersonalMessagesListRoutine({ append })),
    muteChat: () => dispatch(muteChatRoutine()),
    unmuteChat: () => dispatch(unmuteChatRoutine()),
    muteConversation: () => dispatch(muteConversationRoutine()),
    unmuteConversation: () => dispatch(unmuteConversationRoutine()),
    markConversationAsSeen: conversationId =>
      dispatch(markConversationAsSeenRoutine({ conversationId })),
    markMessageAsSeen: payload => dispatch(markMessageAsSeenRoutine(payload)),
    reserveOffer: payload => dispatch(reserveOfferRoutine(payload)),
    acceptReservation: payload => dispatch(acceptReservationRoutine(payload)),
    rejectReservation: payload => dispatch(rejectReservationRoutine(payload)),
    getContextAvailableActions: conversationId =>
      dispatch(getContextAvailableActionsRoutine({ conversationId })),
    cancelReservation: payload => dispatch(cancelReservationRoutine(payload)),
    getMessages: payload => dispatch(getConversationMessagesRoutine(payload))
  })
)

export const connectMessagesBasicData = connect(
  state => ({
    listOpened: isMessagesListOpened(state),
    conversationOpened: isConversationOpened(state),
    messagesCounters: selectMessagesCounters(state),
    isChatMuted: isChatMuted(state)
  }),
  dispatch => ({
    setMessagesListOpened: opened =>
      dispatch(setMessagesListOpenedRoutine({ opened })),
    setConversationOpened: payload =>
      dispatch(setConversationOpenedRoutine(payload)),
    clearLastMessagesLists: () => dispatch(clearLastMessagesListsRoutine()),
    clearConversation: () => dispatch(clearConversationRoutine()),
    muteChat: () => dispatch(muteChatRoutine()),
    unmuteChat: () => dispatch(unmuteChatRoutine())
  })
)
