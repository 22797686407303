import { connect } from 'react-redux'

import { logoutUserRoutine } from 'src/features/auth/duck/actions'

import {
  selectContactCategories,
  selectContactForm
} from 'src/features/account/duck/selectors'
import {
  sendContactFormRoutine,
  setContactFormValueRoutine
} from 'src/features/account/duck/actions'
import {
  setCurrentCompanyValueRoutine,
  setAddUserFormRoutine,
  setEditUserFormRoutine,
  getUserPermissionsRoutine,
  clearUserPermissionsRoutine,
  getCurrentUserDetailsRoutine,
  getCurrentUserCoworkersRoutine,
  updateCurrentUserRoutine,
  updateCurrentUserLanguageRoutine,
  changeCurrentUserPasswordRoutine,
  updateCurrentCompanyRoutine,
  getCurrentCompanyDetailsRoutine,
  uploadUserAvatarRoutine,
  addCurrentCompanyUserRoutine,
  editCurrentCompanyUserRoutine,
  deactivateCompanyUserRoutine,
  deleteCompanyUserRoutine,
  clearEditUserFormRoutine,
  clearAddUserFormRoutine,
  prefillEditUserFormRoutine,
  uploadEmployeeAvatarRoutine
} from './actions'
import {
  selectEditUserForm,
  selectCurrentUser,
  selectCurrentCompanyInformation,
  selectAddUserForm,
  selectCurrentUserPermissions,
  selectCurrentUserCoworkers,
  selectChangePasswordStatus,
  selectUpdateMyCompanyStatus,
  selectCurrentUserCompany,
  selectCurrentUserCompanyId,
  selectUpdateCurrentUserStatus,
  selectCurrentUserAvatarState,
  selectCurrentUserCompanyName
} from './selectors'

export const connectEditCompanyUser = connect(
  state => ({
    editUserForm: selectEditUserForm(state),
    users: selectCurrentUserCoworkers(state),
    currentUser: selectCurrentUser(state),
    editUserStatus: selectEditUserForm(state)
  }),
  dispatch => ({
    setEditUserFormValue: (name, value) =>
      dispatch(setEditUserFormRoutine({ name, value })),
    editUser: () => dispatch(editCurrentCompanyUserRoutine()),
    deleteUser: () => dispatch(deleteCompanyUserRoutine()),
    deactivateUser: () => dispatch(deactivateCompanyUserRoutine()),
    getCurrentUserCoworkers: () => dispatch(getCurrentUserCoworkersRoutine()),
    clearForm: () => dispatch(clearEditUserFormRoutine()),
    prefillEditUserForm: payload =>
      dispatch(prefillEditUserFormRoutine(payload)),
    uploadEmployeeAvatar: payload =>
      dispatch(uploadEmployeeAvatarRoutine(payload))
  })
)

export const connectAddCompanyUser = connect(
  state => ({
    addUserForm: selectAddUserForm(state),
    addUserFormState: selectAddUserForm(state)
  }),
  dispatch => ({
    setAddUserFormValue: (name, value) =>
      dispatch(setAddUserFormRoutine({ name, value })),
    addUser: () => dispatch(addCurrentCompanyUserRoutine()),
    clearForm: () => dispatch(clearAddUserFormRoutine())
  })
)

export const connectUserAccount = connect(
  state => ({
    permissions: selectCurrentUserPermissions(state),
    coworkers: selectCurrentUserCoworkers(state),
    currentUser: selectCurrentUser(state)
  }),
  dispatch => ({
    getUserPermissions: () => dispatch(getUserPermissionsRoutine()),
    clearUserPermissions: () => dispatch(clearUserPermissionsRoutine()),
    getCurrentUserDetails: () => dispatch(getCurrentUserDetailsRoutine()),
    getCurrentUserCoworkers: () => dispatch(getCurrentUserCoworkersRoutine())
  })
)

export const connectEditCurrentUserForm = connect(
  state => ({
    currentUser: selectCurrentUser(state),
    updateCurrentUserStatus: selectUpdateCurrentUserStatus(state)
  }),
  dispatch => ({
    updateCurrentUser: payload => dispatch(updateCurrentUserRoutine(payload)),
    updateCurrentUserLanguage: payload =>
      dispatch(updateCurrentUserLanguageRoutine(payload))
  })
)

export const connectChangePassword = connect(
  state => ({
    changePasswordStatus: selectChangePasswordStatus(state)
  }),
  dispatch => ({
    changePassword: payload =>
      dispatch(changeCurrentUserPasswordRoutine(payload))
  })
)

export const connectCompany = connect(
  state => ({
    updateMyCompanyStatus: selectUpdateMyCompanyStatus(state),
    company: selectCurrentUserCompany(state),
    companyId: selectCurrentUserCompanyId(state),
    companyName: selectCurrentUserCompanyName(state)
  }),
  dispatch => ({
    updateMyCompany: payload => dispatch(updateCurrentCompanyRoutine(payload)),
    getMyCompanyDetails: () => dispatch(getCurrentCompanyDetailsRoutine())
  })
)

export const connectAvatar = connect(
  state => ({
    avatarState: selectCurrentUserAvatarState(state)
  }),
  dispatch => ({
    uploadUserAvatar: payload => dispatch(uploadUserAvatarRoutine(payload))
  })
)

export const connectContactForm = connect(
  state => ({
    contactForm: selectContactForm(state),
    contactCategories: selectContactCategories(state)
  }),
  dispatch => ({
    setContactFormValue: (name, value) =>
      dispatch(setContactFormValueRoutine({ name, value })),
    sendContactForm: () => dispatch(sendContactFormRoutine())
  })
)

export default connect(
  state => ({
    currentUser: selectCurrentUser(state),
    currentCompany: selectCurrentCompanyInformation(state),
    permissions: selectCurrentUserPermissions(state),
    changePasswordStatus: selectChangePasswordStatus(state),
    updateMyCompanyStatus: selectUpdateMyCompanyStatus(state),
    updateCurrentUserStatus: selectUpdateCurrentUserStatus(state),
    avatarState: selectCurrentUserAvatarState(state),
    users: selectCurrentUserCoworkers(state)
  }),
  dispatch => ({
    setCurrentCompanyValue: (name, value) =>
      dispatch(setCurrentCompanyValueRoutine({ name, value })),
    logoutUser: () => dispatch(logoutUserRoutine()),
    getUserPermissions: () => dispatch(getUserPermissionsRoutine()),
    clearUserPermissions: () => dispatch(clearUserPermissionsRoutine()),
    getCurrentUserDetails: () => dispatch(getCurrentUserDetailsRoutine()),
    updateCurrentUser: () => dispatch(updateCurrentUserRoutine()),
    getMyCompanyDetails: () => dispatch(getCurrentCompanyDetailsRoutine()),
    getCurrentUserCoworkers: () => dispatch(getCurrentUserCoworkersRoutine())
  })
)
