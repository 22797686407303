import { useEffect, useRef } from 'react'

export default callback => {
  const componentRef = useRef(null)
  const handleClick = e => {
    if (componentRef.current && componentRef.current.contains(e.target)) return
    callback()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, true)
    return () => {
      document.removeEventListener('mousedown', handleClick, true)
    }
  }, [])

  return componentRef
}
