import React from 'react'
import styled from 'styled-components'
import useClickOutside from 'src/hooks/useClickOutside'
import MUICloseIcon from '@material-ui/icons/Close'

const Drawer = ({ className, children, onClose }) => {
  const componentRef = useClickOutside(onClose)
  return (
    <div className={className} ref={componentRef}>
      <CloseIcon onClick={onClose} />
      {children}
    </div>
  )
}

export default styled(Drawer)`
  display: flex;
  flex-direction: column;
  width: 280px;
  min-height: 100vh;
  position: fixed;
  transform: translateX(${({ open }) => (open ? '0px' : '280px')});
  transition: transform 0.3s;
  right: 0;
  top: 0;
  background-color: white;
  box-shadow: 0 -3px 10px 0 rgba(50, 54, 58, 0.26);
  z-index: 100;
  box-sizing: border-box;
  padding: 2.8rem 1.4rem;
`

const CloseIcon = styled(MUICloseIcon)`
  font-size: 2.2rem !important;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  position: absolute;
  padding: 0.8rem;
  top: 0.8rem;
  right: 0.8rem;
`
