import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import CustomIcon from 'src/components/CustomIcon'

const Alert = ({
  className,
  type,
  message,
  icon,
  title,
  vertical,
  size,
  ...props
}) => (
  <div className={className} {...props}>
    <IconContainer>
      {icon || <StyledCustomIcon name={type} size={size} />}
    </IconContainer>
    <Message vertical={vertical}>
      <Title>{title}</Title>
      <div>{message}</div>
    </Message>
  </div>
)

Alert.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'warning', 'alert', 'white'])
    .isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  icon: PropTypes.element,
  className: PropTypes.string,
  title: PropTypes.string,
  vertical: PropTypes.bool,
  size: PropTypes.number
}

Alert.defaultProps = {
  icon: null,
  className: '',
  title: '',
  vertical: false,
  size: 20
}

export default styled(Alert)`
  display: flex;
  flex-direction: row;
  padding: 10px 18px 10px 0px;
  font-size: 0.75rem;
  border-radius: 4px;
  line-height: 1.67;
  color: ${({ theme }) => theme.colors.black.alpha(0.87).hex()};
  background-color: ${({ theme, type }) => theme.colors[type].hex()};
  box-sizing: border-box;
  & svg {
    color: ${({ type }) => (type === 'success' ? '#008556' : 'inherit')};
  }
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`

export const TimeAlert = styled(Alert)`
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  line-height: 1.67;
  color: ${({ theme }) => theme.colors.black.alpha(0.87).hex()};
  box-sizing: border-box;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 50%;
    `}
`

const IconContainer = styled.div`
  padding: 2px 18px;
  display: flex;
  color: white;
`
const StyledCustomIcon = styled(CustomIcon)`
  display: inline-flex;
  z-index: 0;
  ${({ name }) =>
    name === 'warning' &&
    css`
      color: ${({ theme }) => theme.colors.california.hex()};
    `}
  ${({ name }) =>
    name === 'info' &&
    css`
      color: ${({ theme }) => theme.colors.easternBlue.hex()};
    `}
`

const Message = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ${({ vertical }) => (vertical ? 'row' : 'column')};
  align-items: ${({ vertical }) => (vertical ? 'center' : 'flex-start')};
`

const Title = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  margin-right: 1rem;
`
