import React, { useState, useEffect } from 'react'
import qs from 'qs'
import { isNil } from 'ramda'
import styled from 'styled-components'
import { Link, FormattedMessage } from 'gatsby-plugin-intl'
import { breakpoint } from 'src/theme/grid'
import MUIDownArrow from '@material-ui/icons/KeyboardArrowDown'
import useClickOutside from 'src/hooks/useClickOutside'
import { DROPDOWN_NAVIGATION_LIST } from 'src/features/account/duck/consts'
import appRegistry from 'src/services/AppRegistry'
import { Avatar } from 'src/components/atoms/Avatar'
import CustomIcon from 'src/components/CustomIcon'
import { IMPERSONATE_SESSION_KEY } from 'src/ducks/consts'
import { PopupNavigationElement } from './atoms'

const AccountPopupComponent = ({
  className,
  onClose,
  onClickOutside,
  logoutUser,
  location,
  url,
  name,
  email
}) => {
  const componentRef = useClickOutside(onClickOutside)
  const [activeTab, setActiveTab] = useState(null)
  const isImpersonated = !isNil(
    JSON.parse(appRegistry.session.get(IMPERSONATE_SESSION_KEY))
  )
  useEffect(() => {
    const params = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })
    setActiveTab(params.tab)
  }, [location.search])

  return (
    <div className={className} ref={componentRef}>
      <PopupNavigationUserBox>
        <Avatar url={url} />
        <NameContainer>
          <Name>{name}</Name>
          <Email>{email}</Email>
        </NameContainer>
      </PopupNavigationUserBox>
      {DROPDOWN_NAVIGATION_LIST.map((link, key) => (
        <Link to={link.url} key={key}>
          {link.withSeparator && <Separator />}
          <PopupNavigationElement
            hasIcon={link.icon}
            onClick={onClose}
            active={link.activeWhen.includes(activeTab)}
          >
            {link.icon}
            <span><FormattedMessage id={link.name} /></span>
          </PopupNavigationElement>
        </Link>
      ))}
      <Separator />
      <PopupNavigationElement onClick={logoutUser} hasIcon>
        <CustomIcon name='power' size={24} />
        <span><FormattedMessage id={isImpersonated ? 'Wróć do panelu' : 'navigation.logout'} /></span>
      </PopupNavigationElement>
    </div>
  )
}

const AccountAvatarComponent = ({ className, onClick, active, url }) => (
  <div className={className} onClick={onClick}>
    <Avatar url={url} />
    <AccountArrow active={active} />
  </div>
)

export const AccountPopup = styled(AccountPopupComponent)`
  width: 290px;
  box-sizing: border-box;
  padding: 0;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  position: absolute;
  top: 54px;
  right: 0;
  display: none;
  flex-direction: column;
  ${({ active }) =>
    active &&
    `
      display: flex;
  `}
`

export const AccountAvatar = styled(AccountAvatarComponent)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AccountArrow = styled(MUIDownArrow)`
  font-size: 1.7rem !important;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  margin-left: 0.4rem;
  transition: transform 0.3s;
  ${({ active }) =>
    active &&
    `
      transform: rotate(180deg);
  `}
  display: none;
  ${breakpoint.m`
    display: inline-block;
  `}
`

const PopupNavigationUserBox = styled.div`
  padding: 0.6rem 1rem;
  cursor: default;
  display: flex;
  align-items: center;

  ${Avatar} {
    margin-right: 0.75rem;
  }
`

export const Name = styled.div`
  font-size: 0.75rem;
  letter-spacing: 0.33px;
  color: #000000;
  font-weight: 600;
  opacity: 0.87;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Email = styled.div`
  font-size: 0.75rem;
  letter-spacing: 0.33px;
  color: #000000;
  font-weight: 400;
  opacity: 0.6987295386904762;
  overflow: hidden;
  text-overflow: ellipsis;
`

const NameContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

const Separator = styled.span`
  display: block;
  border-top: 1px solid rgba(36, 45, 63, 0.15);
  margin: 0 1rem;
`
