import { useState } from 'react'
import appRegistry from 'src/services/AppRegistry'

export default key => {
  const [information, _setInformation] = useState(
    JSON.parse(appRegistry.storage.get(key))
  )

  const setInformation = data => {
    appRegistry.storage.set(key, data)
    _setInformation(data)
  }

  return [information, setInformation]
}
