import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import HeaderDefault from 'src/components/header/HeaderDefault'
import Footer from 'src/components/Footer'

import favicon16 from 'src/assets/favicon-16x16.png'
import favicon32 from 'src/assets/favicon-32x32.png'

import CookiesBar from 'src/components/CookiesInformation'
import GlobalSnackbar from 'src/components/Snackbar'
import { with100vh } from 'src/hooks/use100vh'

const Layout = ({ className, children, isUserLoggedIn }) => {
  const Container = isUserLoggedIn ? ContainerFullHeight : Content
  return (
    <div className={className}>
      <Helmet>
        <link rel='icon' type='image/png' sizes='32x32' href={favicon32} />
        <link rel='icon' type='image/png' sizes='16x16' href={favicon16} />
      </Helmet>
      <GlobalSnackbar />
      {!isUserLoggedIn && <HeaderDefault />}
      <Container>{children}</Container>
      {!isUserLoggedIn && <Footer />}
      <CookiesBar />
    </div>
  )
}

export default styled(Layout)`
  height: 100%;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  background-color: ${({ theme }) => theme.colors.wildSand.hex()};
`

const Content = styled.div`
  width: 100%;
  min-height: calc(100vh - ${({ theme }) => theme.dimensions.topBarHeight});
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: visible;
  position: relative;
`

const ContainerFullHeight = with100vh(styled.div`
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: column;
`)
