import { connect } from 'react-redux'

import {
  setNotificationsListOpenedRoutine,
  getNotificationsListRoutine,
  markAllNotificationsAsReadRoutine,
  markAllNotificationsAsSeenRoutine,
  clearNotificationsRoutine,
  markNotificationAsReadRoutine,
  getNotificationsSettingsRoutine,
  updateNotificationSettingRoutine
} from './actions'

import {
  isNotificationsListOpened,
  selectNotificationsList,
  selectNotificationsUnreadCount,
  isNotificationListLoading,
  selectAllNotificationsFetched,
  selectNotificationsSettings
} from './selectors'

export const connectNotifications = connect(
  state => ({
    notificationsListOpened: isNotificationsListOpened(state),
    notifications: selectNotificationsList(state),
    notificationsUnreadCount: selectNotificationsUnreadCount(state),
    isListLoading: isNotificationListLoading(state),
    allFetched: selectAllNotificationsFetched(state)
  }),
  dispatch => ({
    setNotificationsListOpened: opened =>
      dispatch(setNotificationsListOpenedRoutine({ opened })),
    getNotificationsList: append =>
      dispatch(getNotificationsListRoutine({ append })),
    markAllNotificationsAsRead: () =>
      dispatch(markAllNotificationsAsReadRoutine()),
    markAllNotificationsAsSeen: () =>
      dispatch(markAllNotificationsAsSeenRoutine()),
    clearNotifications: () => dispatch(clearNotificationsRoutine()),
    markNotificationAsRead: id =>
      dispatch(markNotificationAsReadRoutine({ id }))
  })
)

export const connectNotificationsBasics = connect(
  state => ({
    notificationsListOpened: isNotificationsListOpened(state),
    notificationsSettings: selectNotificationsSettings(state)
  }),
  dispatch => ({
    getNotificationsSettings: () => dispatch(getNotificationsSettingsRoutine()),
    updateNotificationSetting: payload =>
      dispatch(updateNotificationSettingRoutine(payload))
  })
)
