import React from 'react'
import { ThemeProvider } from 'styled-components'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import GlobalStyles from 'src/theme/globalStyles'

import theme from 'src/theme/theme'
import muiTheme from 'src/theme/muiTheme'
import { with100vh } from 'src/hooks/use100vh'

const Theme = ({ className, children }) => (
  <>
    <GlobalStyles />
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <div className={className}>{children}</div>
      </MuiThemeProvider>
    </ThemeProvider>
  </>
)

export default with100vh(Theme)
