import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import { compose } from 'recompose'
import { breakpoint, breakpoints } from 'src/theme/grid'
import connect from 'src/features/account/duck/connectors'
import { connectGlobalLoader } from 'src/ducks/connectors'
import Loader from 'src/components/LinearLoader'
import { connectMessagesBasicData } from 'src/features/messages/duck/connectors'
import { connectNotifications } from 'src/features/notifications/duck/connectors'
import { Location } from '@reach/router'
import MUIChatBubble from '@material-ui/icons/ChatBubbleOutline'
import MUIBurger from '@material-ui/icons/Menu'
import MUINotification from '@material-ui/icons/NotificationsOutlined'
import MUILeftArrow from '@material-ui/icons/KeyboardArrowLeft'
import { NavigationList, NavigationElement } from './components/atoms'
import { AccountPopup, AccountAvatar } from './components/Account'
import { NAVIGATION } from './consts'
import { useIntl } from 'gatsby-plugin-intl'

export const HeaderContext = React.createContext({ onBurgerAction: () => {} })

const Header = ({
  className,
  logoutUser,
  children,
  displayBurger = true,
  currentUser: { avatar, email, firstName, lastName },
  setMessagesListOpened,
  setNotificationsListOpened,
  notificationsListOpened,
  notificationsUnreadCount,
  listOpened,
  messagesCounters,
  isGlobalLoaderLoading
}) => {
  const [state, setState] = useState({
    fullNavigationVisible: false,
    activeNavigation: null
  })
  const intl = useIntl()
  const isDemoMode = process.env.GATSBY_DEMO_MODE === 'true'
  const { onBurgerAction } = useContext(HeaderContext)
  const toggle = type => {
    setState({
      //hide on click on the same icon
      fullNavigationVisible: !(state.activeNavigation === type),
      activeNavigation: state.activeNavigation === type ? null : type
    })
  }
  const setClose = () =>
    setState({ fullNavigationVisible: false, activeNavigation: null })
  const onClickOutsideNavigationDropdown = () => {
    //disable click outside on mobile
    const BREAKPOINT_S = parseInt(breakpoints.s.replace('px', ''), 10)
    window.innerWidth > BREAKPOINT_S &&
      setState({ ...state, activeNavigation: null })
  }

  return (
    <Location>
      {({ location }) => (
        <nav className={className}>
          <LoaderStyled loading={isGlobalLoaderLoading} />
          <ChildrenWrapper>
            {displayBurger && <Burger onClick={onBurgerAction} />}
            {children}
          </ChildrenWrapper>
          <NavigationListStyled
            fullNavigationVisible={state.fullNavigationVisible}
          >
            <NavigationElement>
              {isDemoMode && (
                <div
                  style={{
                    color: '#999999',
                    backgroundColor: '#f7f7f7',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    fontSize: '0.8rem',
                    margin: '5px 0'
                  }}
                >
                  {intl.formatMessage({ id: 'common.thisIsADemoVersion' })}
                </div>
              )}
              <LeftArrowIcon />
              <IconWrapper active={notificationsListOpened}>
                <Notification
                  onClick={() =>
                    setNotificationsListOpened(!notificationsListOpened)
                  }
                />
                {notificationsUnreadCount > 0 && (
                  <Counter
                    onClick={() =>
                      setNotificationsListOpened(!notificationsListOpened)
                    }
                  >
                    {notificationsUnreadCount}
                  </Counter>
                )}
              </IconWrapper>
              <IconWrapper active={listOpened}>
                <MessagesIcon
                  onClick={() => setMessagesListOpened(!listOpened)}
                />
                {messagesCounters.allUnreadMessagesCount > 0 && (
                  <Counter onClick={() => setMessagesListOpened(!listOpened)}>
                    {messagesCounters.allUnreadMessagesCount}
                  </Counter>
                )}
              </IconWrapper>
              <IconWrapper>
                <AccountAvatar
                  url={avatar.url}
                  onClick={() => toggle(NAVIGATION.ACCOUNT)}
                  active={state.activeNavigation === NAVIGATION.ACCOUNT}
                />
                {state.activeNavigation === NAVIGATION.ACCOUNT && (
                  <AccountPopup
                    onClose={() => setClose()}
                    onClickOutside={onClickOutsideNavigationDropdown}
                    logoutUser={logoutUser}
                    active={state.activeNavigation === NAVIGATION.ACCOUNT}
                    location={location}
                    url={avatar.url}
                    name={`${firstName} ${lastName}`}
                    email={email}
                  />
                )}
              </IconWrapper>
            </NavigationElement>
          </NavigationListStyled>
        </nav>
      )}
    </Location>
  )
}

const ConnectedComponent = compose(
  connect,
  connectMessagesBasicData,
  connectNotifications,
  connectGlobalLoader
)(Header)

export default styled(ConnectedComponent)`
  display: flex;
  height: ${({ theme }) => theme.dimensions.topBarHeight};
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  z-index: 100;
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: 100vw;
  ${breakpoint.m`
    width: 100%;
  `}
`

export const HeaderGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  min-width: 0;
  padding-right: 83px;
  ${breakpoint.s`
      padding-right: 0;
  `}
`

const IconWrapper = styled.span`
  margin-right: 15px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: -7px;
        left: -7px;
        width: 43px;
        height: 43px;
        opacity: 0.15;
        border-radius: 20px;
        background-color: #13a2bf;
      }
      ${MessagesIcon} {
        color: #13a2bf;
      }
    `}
  ${breakpoint.sm`
    margin-right: 1.7rem;
    display: inline-block;
  `}
`

const LeftArrowIcon = styled(MUILeftArrow)`
  && {
    font-size: 1.7rem;
  }
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  margin-right: 0.2rem;
  display: inline-block;
  ${breakpoint.s`
    display: none;
  `}
`

const MessagesIcon = styled(MUIChatBubble)`
  font-size: 1.7rem !important;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  transition: color 0.3s;
  margin-right: 0.4rem;
  position: relative;
  display: flex;
  z-index: 100;
  &:hover {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
`

const Notification = styled(MUINotification)`
  font-size: 1.7rem !important;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  transition: color 0.3s;
  margin-right: 0.4rem;
  &:hover {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
`

const Burger = styled(MUIBurger)`
  font-size: 2.2rem !important;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  height: 100%;
  display: flex;
  opacity: 0.7;
  margin: 0 0.4rem;
  align-items: center;
  cursor: pointer;
  ${breakpoint.s`
    font-size: 2.4rem !important;
    margin: 0 1rem;
  `}
  ${breakpoint.m`
    display: none;
  `}
`

const NavigationListStyled = styled(NavigationList)`
  display: flex;
  padding-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: white;
  width: 83px;
  ${breakpoint.s`
    padding-right: 30px;
    position: relative;
  `}
  ${IconWrapper} {
    display: none;
  }
  ${({ fullNavigationVisible }) =>
    fullNavigationVisible &&
    css`
      width: 180px;
      overflow: visible;
      ${IconWrapper} {
        display: flex;
      }
    `}
  ${IconWrapper}:last-child {
    display: flex;
  }
  ${breakpoint.s`
    width: auto;
    ${IconWrapper} {
      display: flex;
    }
  `}
`
const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 0;
`

const Counter = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.fadedRed.hex()};
  position: absolute;
  top: -10px;
  right: -4px;
  overflow: hidden;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-size: 14px;
  font-weight: 600;
`

export const LoaderStyled = styled(Loader)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
`
